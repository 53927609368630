import React from "react";
import Item from "./Item";
import {AnimateSharedLayout} from "framer-motion";
import {Grid} from "@mui/material";

export default function Items(props) {




    return (
        <div className={"breathe"}>
            <AnimateSharedLayout>
                <Grid container gap={2} display={"flex"} direction={"row"} justifyContent={"space-between"} >

                    {props.items.map((item, i) => {
                        return (
                            <Grid item key={i} minWidth={"350px"} width={"30%"} flexGrow={"1"} maxWidth={"600px"}>

                            <Item item={item} currencySymbol={props.currencySymbol}/>
                            </Grid>
                                )
                    })}
                </Grid>
            </AnimateSharedLayout>
        </div>
    )
}
