import React, {useContext, useEffect} from "react";
import {Button, Card, Grid, Typography} from "@mui/material";
import Logo from "../Components/Logo";
import Typewriter from 'typewriter-effect';
import {signInWithPopup, signOut, signInWithRedirect} from "firebase/auth";
import {auth, googleProvider} from "../firebase";
import {UserContext} from "../context";
import {useSnackbar} from "notistack";
import {ArrowCircleRight, Cancel} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";

export default function SignIn() {

  const navigate = useNavigate();
  const [user, setUser, loading] = useContext(UserContext);
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  //
  // useEffect(() => {
  //     return onAuthStateChanged(auth,user => {
  //         if (user) {
  //             // console.log(user)
  //             // User is signed in. Redirect
  //         }
  //     });
  // }, []);
  useEffect(() => {
    //redirect if user is logged in
    if (!loading && user) {
      enqueueSnackbar(
          "You're logged in. Redirecting to your dashboard in 3 seconds", {
            variant: "success",
            persist: true,
            action: (key) => {
              return <><ArrowCircleRight onClick={() => {
                navigate("/dashboard");
                closeSnackbar(key);
              }}/><Cancel onClick={() => closeSnackbar()}/></>
            }
          })
      setTimeout(() => {
        navigate("/dashboard")
        closeSnackbar()
      }, 3000)
    }
  }, [user]);

  return (
      <Grid container alignItems={"center"} justifyContent={"center"}
            sx={{p: "2rem", height: "100vh"}} spacing={1}>
        <Grid item xs={12} md={6} sx={{height: "100%"}}>
          <Card sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center"
          }}>
            <Logo width={"300px"}/>
            <Typography variant={"h1"} fontSize={"3rem"}> Welcome!</Typography>

            {!user ? <Button

                onClick={() => {
                  //login with Google

                  signInWithRedirect(auth,googleProvider).then((result) => {
                    // const credential = GoogleAuthProvider.credentialFromResult(result);
                    // const token = credential.accessToken;
                    // console.log(result.user)
                    setUser(result.user)
                  })
                }}

                sx={{

                  backgroundImage: "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=)",
                  backgroundColor: "white",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "1px 8px",
                  pl: "25px"

                }}>Sign In With Google</Button> : <Typography>
              Logged In as {user?.email}
              <Button onClick={() => {
                signOut(auth).then()
              }}>Logout?</Button>
            </Typography>}
          </Card>
        </Grid>
        <Grid item md={6}
              sx={{height: "100%", display: {xs: "none", lg: "block"}}}>
          <Card sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            backgroundImage: "linear-gradient(0deg, rgb(0, 0, 0) 2%, rgba(255, 255, 255, 0) 42%), url(/images/stacked-peaks.svg)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}>
            <Typography variant={"h1"} fontSize={"6rem"} width={"50%"}
                        alignSelf={"flex-start"} pl={"2rem"} fontWeight={"bold"}
                        color={"#fff"}>
              <Typewriter
                  options={{
                    strings: ['A Menu Experience From The Future.'],
                    autoStart: true,
                    loop: true,
                  }}
              />
            </Typography>


          </Card>
        </Grid>
      </Grid>
  )

}
