import {Box, Skeleton} from "@mui/material";

export default function CoverSkeleton() {
    return (<>

        <Box sx={{

        }}>
            <Skeleton
                variant={"rectangular"}
                animation={"wave"}
                height={"40vh"}
                width={"100%"}
                style={{
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center"
                }}
            />


        </Box>
        </>)
}