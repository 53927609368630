import React from "react";
import {Typography} from "@mui/material";

export default function CoverPhoto(props){
    return (
        <div className={"coverPhotoParent"} style={{
            backgroundImage:
           `linear-gradient(2deg, rgba(29, 29, 29, 1) 2%, rgba(29, 29, 29, 0.05)),url('${props.featuredImage}')`
        }}>
            <div className={"coverPhotoInnerDiv"}>
                <Typography variant={"h2"} fontSize={"2rem"} align={"center"} color={"#fff"}>Welcome to</Typography>
                <Typography variant={"h1"} fontSize={"3rem"} align={"center"} color={"#fff"}>{props.venueName}</Typography>
            </div>
        </div>
    )
}
