import React, {useState} from "react";
import {motion, AnimatePresence} from "framer-motion";
import {Box, Chip, Typography} from "@mui/material";

export default function Item(props) {

    const [openItem, setOpenItem] = useState();
    const toggleItem = () => setOpenItem(!openItem);

    const MotionText = motion(Typography)
    const MotionBadge = motion(Chip)
    const MotionBox = motion(Box)


    return (
        <motion.div
            onClick={toggleItem}
            className={"itemBox"}
            style={{
                backgroundImage:
                    `linear-gradient(0deg, #000000 2%, rgb(255 255 255 / 0%)42%),url('${props.item.data().pictureURL}')`
            }}>
            {props.item.data().featured &&
            <MotionBadge sx={{backgroundColor:"#15d246"}} label={"Recommended"} />}
            <MotionText layout color={"white"} fontWeight={"bold"}>{props.item.data().name}</MotionText>
            <MotionText layout color={"white"}
                        fontWeight={"bold"}>{props.currencySymbol + props.item.data().price}</MotionText>


            <motion.div
                initial={{ borderRadius: 10 }}>
                <AnimatePresence>{openItem &&
                //hidden content
                <motion.div
                    initial={{opacity: 0}}
                    style={{
                        maxWidth:"80%"
                    }}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{duration: 0.5}}
                >
                    <MotionText color={"white"} >{props.item.data().description}</MotionText>
                    <MotionBox display={"flex"} flexWrap={"wrap"} gap={"5px"}>
                        {props.item.data().ingredients?.length > 0 && props.item.data().ingredients.map((ingredient, i) => {
                            return (
                                <MotionBadge key={i} label={ingredient.ingredientName} sx={{backgroundColor : "white", textTransform:"capitalize"}}></MotionBadge>
                            )
                        })}
                    </MotionBox>
                </motion.div>
                }</AnimatePresence>
            </motion.div>

        </motion.div>
    )
}
