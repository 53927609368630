import React, {useEffect, useState} from "react";
import {collection, getDocs, limit, orderBy, query, startAfter, where} from "firebase/firestore";
import CoverPhoto from "./CoverPhoto";
import {db} from "../firebase";
import {useNavigate, useSearchParams} from "react-router-dom";
import Categories from "./Categories";
import Items from "./Items";
import {Typography} from "@mui/material";
import CategorySkeleton from "./CategorySkelaton";
import CoverSkeleton from "./CoverSkelaton";


//Builds the home page based off of the unique url of the venue
export default function Venue() {
    //get document from URL and save it as a state

    const [venueData, setVenueData] = useState(null);
    const [venueLoading, setVenueLoading] = useState(true);
    const [itemsLoading, setItemsLoading] = useState(true);
    const [currentCategory, setCurrentCategory] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [items,setItems] = useState(null);
    let navigate = useNavigate();

    //get venue data
    useEffect(() => {
        //get data from firebase
        const venueQuery = query(collection(db, "venues"), where("url", "==", window.location.pathname.split("/")[2]));
        getDocs(venueQuery).then((venue) => {
            setVenueData({...venue.docs[0].data(),id: venue.docs[0].id})
            setVenueLoading(false)
        }).catch(() => {
            navigate("/404")
        })

    }, [])


    //get initial 10 items
    useEffect( ()=>{


        if(venueData?.id)
        {

            let  queryConstraints = []
            if(searchParams.get("category"))
            {
                queryConstraints.push(where("categoryName","==",searchParams.get("category")))
                setCurrentCategory(searchParams.get("category"))
            }
            else
                queryConstraints.push(where("featured","==",true));

            let itemsQuery = query(collection(db,"items"),where("venue","==",venueData.id),...queryConstraints,limit(10))

            getDocs(itemsQuery).then((response)=>{
                setItems(response.docs)
                setItemsLoading(false);
            })
        }

    },[venueData])

    const loadItems = (category,scroll = false)=>{
        setItemsLoading(true);
        let itemsQuery =
            scroll?
                query(collection(db,"items"),
                    where("venue","==",venueData.id),
                    where("categoryName","==",category),
                    limit(10),orderBy("name"),startAfter(items[items.length-1]))
                :
                query(collection(db,"items"),where("venue","==",venueData.id),
                    where("categoryName","==",category),limit(10))

        getDocs(itemsQuery).then((response)=>{
            setItems(response.docs)
            setItemsLoading(false);
        })
    }

    const handleCategoryChange = (category)=>{
        loadItems(category)
        setCurrentCategory(category)
        setSearchParams({category:category})
    }

    return (<>
        {venueLoading ? <CoverSkeleton/>
            :  venueData && <CoverPhoto featuredImage={venueData.featuredImage} venueName={venueData.name}/> }
        {venueLoading ? <CategorySkeleton/>
                : venueData && <div onClick={(e)=>{
            if(e.target.children.length === 1) handleCategoryChange(e.target.children[0].innerText)}}>
            <Categories categories={venueData.categories}/></div>}
            {venueData &&
            <div className={"globalMargin"}>
                <Typography align={"left"} variant={"h2"} fontSize={"2rem"}>{currentCategory}</Typography>
                { !itemsLoading && <Items items={items} currencySymbol={venueData.currencySymbol} />}
            </div>
            }
        </>
    )
}
