import {useContext, useEffect} from "react";
import {UserContext} from "../context";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";

export default function Dashboard(props){

  const navigate = useNavigate();

  const [user, setUser, loading] = useContext(UserContext);
  const {enqueueSnackbar} = useSnackbar();


  useEffect(()=>{
    //check if user is logged in. If not redirect
    if(!loading && !user) {
      console.log("loading has", loading)
      console.log("user has", user)
      navigate("/?notLoggedIn=true")
    }
    //get user data and set it as a state



  },[user, loading])



  return <>
  </>
}
