// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth,GoogleAuthProvider } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDYgYEWvtM9bDlJEFbpAFVrgkTUw9A22nE",
    authDomain: "mnuapp-project.firebaseapp.com",
    projectId: "mnuapp-project",
    storageBucket: "mnuapp-project.appspot.com",
    messagingSenderId: "714275125779",
    appId: "1:714275125779:web:f700ced53c5f969e359de6",
    measurementId: "G-ZN8MJF03LS"
};

// Initialize Firebase
initializeApp(firebaseConfig);

const firebaseApp = initializeApp(firebaseConfig);

export const auth = getAuth(firebaseApp);
export const googleProvider = new GoogleAuthProvider();

export const analytics = getAnalytics();
export const firestore = getFirestore(firebaseApp);
export const db = getFirestore(firebaseApp);


