import React, {useContext, useEffect} from "react";
import Hero from "./Hero";
import {UserContext} from "../context";
import {Button, Typography} from "@mui/material";
import {useSnackbar} from 'notistack';
import {useNavigate} from "react-router-dom";

export default function LandingPage() {
  const [user,setUser,loading] = useContext(UserContext);
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    //shows notifications if needed
    const urlParams = new URLSearchParams(window.location.search);
    if (!loading && !user && urlParams.get("notLoggedIn")) {
      enqueueSnackbar("It looks like you're not logged in.", {
        variant: "error", action: <Button
            onClick={() => {
              navigate("/sign-in")
            }}
            sx={{color: "#fff"}}>Log In</Button>
      })
    }

  },[user,loading])

  return (
      <>
        <Hero title={"A Menu Experience From The Future"}
              mainCTA={{display: "View Demo", path: "/venue/pelican-grill"}}
              secondaryCTA={!loading && !user ? {display: "Sign Up", path: "/sign-in"}
                  : {display: "Your Dashboard", path: "/dashboard"}}
        />
      </>
  )
}
