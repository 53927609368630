import React from "react";
import {Link} from "react-router-dom";
import {Typography} from "@mui/material";

export default function _404(){
    return (
        <div>
            <Typography>
                Ooops! Looks like this page does not exists right now.
            </Typography>
            <Link to={'/sign-in'}>Create it!</Link>
        </div>
    )
}
