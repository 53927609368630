import './App.css';
import {UserContext} from "./context";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {useEffect, useState} from "react";

import SignIn from "./Pages/SignIn";
import LandingPage from "./Components/LandingPage";
import _404 from "./Components/_404";
import Venue from "./Components/Venue";
import {auth} from "./firebase";
import {onAuthStateChanged} from 'firebase/auth';
import MnuDrawer from "./Components/MnuDrawer";
import Dashboard from "./Pages/Dashboard";


function App() {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        return onAuthStateChanged(auth,user => {
            if (user) {

                console.log(user)
                // User is signed in.
                setUser(user);
                setLoading(false)
            } else {
                // User is signed out.
                // signInAnonymously(auth).then()
                setUser(null);
               setLoading(false)
            }
        });
    }, []);


    return (

        <UserContext.Provider value={[user,setUser,loading]}>
            <Router>
            <div className="App">
               <MnuDrawer/>
                    <Routes>
                        <Route path="/venue/:url"  element={<Venue/>}/>
                        <Route path="/sign-in" element={<SignIn/>}/>
                        <Route path="/dashboard" element={<Dashboard/>}/>
                        <Route path="/" element={<LandingPage/>}/>
                        <Route path={"*"} element={<_404/>}/>
                    </Routes>
            </div>
            </Router>
        </UserContext.Provider>
    );
}

export default App;
