import {
    Button,
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {Link, useNavigate} from "react-router-dom";
import {
    AccountCircleOutlined,
    DashboardCustomizeOutlined,
    HomeOutlined,
    LegendToggleOutlined
} from "@mui/icons-material";
import {useContext, useState} from "react";
import {signOut} from "firebase/auth";
import {UserContext} from "../context";
import {auth} from "../firebase";

export default function MnuDrawer(){

    const [user,setUser] = useContext(UserContext);
    const navigate = useNavigate();


    const [drawerOpen, setDrawerOpen] = useState(false)

    const openDrawer = ()=>{
        setDrawerOpen(!drawerOpen)
    }
    return ( <>
        <Button onClick={openDrawer} sx={{
            position : "absolute",
            top : "20px",
            right : "20px",
            zIndex : "2"
        }}>
            <MenuIcon/>
        </Button>
        <Drawer  variant="temporary" open={drawerOpen} onClose={()=>setDrawerOpen(false)} anchor={"right"}>
            <img src={"https://i.imgur.com/Byz3gnm.png"}  width={"200px"} style={{padding:"1rem"}} alt={"Mnu Logo"}/>
            <List>
                {user?
                    <><ListItem disablePadding>
                            <ListItemButton onClick={()=>{signOut(auth).then(()=>{navigate("/")});setDrawerOpen(false)}}>
                                <ListItemIcon>
                                    <AccountCircleOutlined/>
                                </ListItemIcon>
                                <ListItemText>
                                   Sign out
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>

                    <ListItem disablePadding>
                        <ListItemButton onClick={()=>{navigate("/dashboard");setDrawerOpen(false)}}>
                            <ListItemIcon>
                                <DashboardCustomizeOutlined/>
                            </ListItemIcon>
                            <ListItemText>
                                Dashboard
                            </ListItemText>
                        </ListItemButton>
                    </ListItem></>


                     :
                <Link to={"/sign-in"}>
                    <ListItem disablePadding>
                        <ListItemButton onClick={()=>{setDrawerOpen(false)}}>
                            <ListItemIcon>
                                <AccountCircleOutlined/>
                            </ListItemIcon>
                            <ListItemText>
                                Log In
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                </Link> }

            </List>
            <Divider/>
            <List>
                <Link to={"/"}>
                    <ListItem disablePadding>
                        <ListItemButton onClick={()=>{setDrawerOpen(false)}}>
                            <ListItemIcon>
                                <HomeOutlined/>
                            </ListItemIcon>
                            <ListItemText>
                                Home
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                </Link>

                <ListItem disablePadding>
                    <ListItemButton onClick={()=>{setDrawerOpen(false)}}>
                        <ListItemIcon>
                            <LegendToggleOutlined/>
                        </ListItemIcon>
                        <ListItemText>
                            Legal
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
            </List>
            <Typography align={"center"}>
                With ♥ from California
            </Typography>
        </Drawer>
    </>)
}
