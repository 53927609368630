import React from "react";
import {Button, Typography} from "@mui/material";
import {Link} from "react-router-dom";

export default function Hero(props){
    return (
        <div style={{
            minHeight:"69vh",
            background : "tomato",
            display: "flex",
            alignContent : "flex-start",
            justifyContent : "center",
            flexDirection : "column"
        }}>
            <Typography variant={"h1"} fontSize={"3rem"} sx={{
                pl:"1rem"
            }}>
                {props.title}
            </Typography>
            <div style={{alignSelf:"start",gap:"1rem", display:"flex", paddingTop:"2rem"}} className={"globalMargin"}>
                <Link to={props.mainCTA.path}><Button variant={"contained"}>{props.mainCTA.display}</Button></Link>
                <Link to={props.secondaryCTA.path}><Button variant={"text"}>{props.secondaryCTA.display}</Button></Link>
            </div>
        </div>
    )
}
