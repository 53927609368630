import React from "react";
import {Grid, Typography} from "@mui/material";

export default function Categories(props){
    return (
        <div className={"globalMargin"}>
            <Typography pt={"1rem"} align={"left"} variant={"h1"} fontSize={"2rem"}>Explore Categories</Typography>



            <Grid container gap={2} sx={{overflowX:"scroll", cursor:"grab"}} maxHeight={"200px"} direction={"column"}>
                {props.categories.length>0 && props.categories.map((category,i)=>{
                    return (
                            <Grid  item padding={2}  key={i} borderRadius={"25px"} height={"200px"} minWidth={"200px"} bgcolor={"orange"} display={"flex"} alignItems={"flex-end"}>
                                <Typography fontWeight={"bold"} color={"#fff"}>
                                    {category.name}
                                </Typography>
                            </Grid>
                    )
                })}
            </Grid>

        </div>
    )
}
